import React from "react";

import Link from "components/Common/Link";
import config from "config/news";

type Props = {
  path?: string;
  className?: string;
};

export default function NewsSidebarContent({ path, className = "" }: Props) {
  const { metadata } = config;

  return (
    <nav className={className}>
      {/* Form */}
      <form className="mt-6 mb-4 lg:hidden" action={path ?? "/news"}>
        <div className="pt-0 mb-3">
          <input
            type="text"
            placeholder="Search news..."
            className="w-full h-12 px-3 py-2 text-base font-normal leading-snug text-foreground-300 placeholder-gray-400 bg-background-400 border border-background-500 border-solid rounded shadow-none outline-none focus:outline-none"
          />
        </div>
      </form>

      {/* Yahoo News */}

      {/* Heading */}
      <h6 className="block pt-4 pb-2 mt-4 text-xs font-bold no-underline uppercase border-t lg:min-w-full text-foreground-300 border-background-300">
        <i className="pr-2 fas fa-newspaper" />
        Yahoo
      </h6>
      {/* Navigation */}

      <ul className="flex flex-col list-none lg:flex-col lg:min-w-full">
        <li className="items-center">
          <Link
            href="/yahoo"
            className="block py-2 text-sm font-semibold capitalize "
          >
            All
          </Link>
        </li>

        {metadata.yahoo.categories.map(
          (cat: { id: string; name: string }, i: number) => {
            return (
              <li className="items-center" key={i}>
                <Link
                  href={`/yahoo/${cat.id}`}
                  className="block py-2 text-sm font-semibold capitalize "
                >
                  {cat.name}
                </Link>
              </li>
            );
          }
        )}

        {metadata.yahoo.keywords.map((keyword: string, i: number) => {
          return (
            <li className="items-center" key={i}>
              <Link
                href={`/yahoo/${keyword}`}
                className="block py-2 text-sm font-semibold capitalize "
              >
                {keyword}
              </Link>
            </li>
          );
        })}
      </ul>

      {/* Vietnamese News */}

      {/* Heading */}
      <h6 className="block pt-4 pb-2 mt-4 text-xs font-bold no-underline uppercase border-t lg:min-w-full text-foreground-300 border-background-300">
        <i className="pr-2 fas fa-newspaper" />
        Sports
      </h6>
      {/* Navigation */}

      <ul className="flex flex-col list-none lg:flex-col lg:min-w-full">
        <li className="items-center">
          <Link
            href="/news"
            className="block py-2 text-sm font-semibold capitalize "
          >
            All
          </Link>
        </li>

        {metadata.news.categories.map(
          (cat: { id: string; name: string }, i: number) => {
            return (
              <li className="items-center" key={i}>
                <Link
                  href={`/news/${cat.id}`}
                  className="block py-2 text-sm font-semibold capitalize "
                >
                  {cat.name}
                </Link>
              </li>
            );
          }
        )}

        {metadata.news.publishers.map((publisher: string, i: number) => {
          return (
            <li className="items-center" key={i}>
              <Link
                href={`/news/publisher/${publisher}`}
                className="block py-2 text-sm font-semibold capitalize "
              >
                {publisher}
              </Link>
            </li>
          );
        })}

        {metadata.news.keywords &&
          metadata.news.keywords.map((keyword: string, i: number) => {
            return (
              <li className="items-center" key={i}>
                <Link
                  href={`/news/keyword/${keyword}`}
                  className="block py-2 text-sm font-semibold capitalize "
                >
                  {keyword}
                </Link>
              </li>
            );
          })}
      </ul>

      {/* NewsPicks */}

      {/* Heading */}
      <h6 className="block pt-4 pb-2 mt-2 text-xs font-bold no-underline uppercase lg:min-w-full text-foreground-300">
        <i className="pr-2 fas fa-newspaper" />
        NewsPicks
      </h6>
      {/* Navigation */}

      <ul className="flex flex-col list-none lg:flex-col lg:min-w-full">
        <li className="items-center">
          <Link
            href="/np"
            className="block py-2 text-sm font-semibold capitalize"
          >
            全てニュース
          </Link>
        </li>
        {metadata.newspicks.categories.map(
          (cat: { id: string; name: string }, i: number) => {
            return (
              <li className="items-center" key={i}>
                <Link
                  href={`/np/${cat.id}`}
                  className="block py-2 text-sm font-semibold capitalize "
                >
                  {cat.name}
                </Link>
              </li>
            );
          }
        )}

        {metadata.newspicks.keywords &&
          metadata.newspicks.keywords.map((keyword: string, i: number) => {
            return (
              <li className="items-center" key={i}>
                <Link
                  href={`/np/keyword/${keyword}`}
                  className="block py-2 text-sm font-semibold capitalize "
                >
                  {keyword}
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
}
