import React, { MouseEventHandler } from "react";
import Icon from "components/Common/Icon";

type Props = {
  className?: string;
  text?: string;
  onClick: MouseEventHandler;
};

export default function LoadMore({ className, text = "", onClick }: Props) {
  return (
    <>
      <div
        className={`w-full p-2 bg-background-400 text-foreground-400 cursor-pointer ${className}`}
        onClick={onClick}
      >
        <div className="w-full h-8">
          <Icon icon="arrow-down" />
          <span className="pl-2">Load more {text}...</span>
        </div>
      </div>
    </>
  );
}
