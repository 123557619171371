import React from "react";
import { PageProps } from "gatsby";
import { NewsParameter } from "components/News/types";
import ServiceYahooCategory from "components/News/ServiceYahooCategory";
import ServiceLazyLoad from "components/News/ServiceLazyLoad";
import NewsLayout from "layouts/NewsLayout";
import { useQueries } from "libs/utils";

export default function Page({
  pageContext,
}: PageProps<object, NewsParameter>) {
  const [{ q: query, p: page }] = useQueries(["q", "p"]);
  const title = `${pageContext.keyword || pageContext.name || ""} ${
    pageContext.query || query || ""
  } Yahoo News`;

  return (
    <NewsLayout title={title}>
      <ServiceLazyLoad
        className="bg-background"
        title={title}
        component={ServiceYahooCategory}
        category={pageContext.category || ""}
        keyword={pageContext.keyword || ""}
        query={pageContext.query || query}
        page={parseInt(page || "0")}
      />
    </NewsLayout>
  );
}
