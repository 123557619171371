import React, { useEffect, useState, ComponentType } from "react";

import LoadMore from "components/Common/LoadMore";
import { getQuery } from "libs/utils";

type ComponentProps = {
  page: number;
};
type Props = {
  component: ComponentType<ComponentProps>;
  title: string;
  className?: string;
  category?: string;
  publisher?: string;
  keyword?: string;
  query?: string;
  page?: number;
};
export default function ServiceLazyLoad({
  component: Component,
  title,
  className,
  page,
  ...props
}: Props) {
  const [p, setP] = useState(!page || page < 1 ? 0 : page);

  useEffect(() => {
    const q = getQuery();
    setP(parseInt(q.get("p") ?? "0"));
  }, [p]);

  const setPage = (i: number) => {
    setP(i);
    let currentParams = new URLSearchParams(window.location.search);
    currentParams.set("p", i.toString());
    history.pushState(
      null,
      document.title,
      window.location.pathname + "?" + currentParams.toString()
    );
  };

  let LazyContents = [];
  for (let i = 0; i <= p; i++) {
    LazyContents.push(<Component {...props} page={i} key={i} />);
  }

  return (
    <div className={`pt-6 px-0 lg:pt-12 lg:px-2 {$className}`}>
      <div className="container z-10 mb-4 ml-3">
        <div className="text-center flex justify-between">
          <h3 className="text-foreground-200 text-3xl text-semibold capitalize">
            {title}
          </h3>
        </div>
      </div>

      <div className="w-full flex flex-wrap">{LazyContents}</div>

      <div className="w-full pb-4 lg:pb-8">
        <LoadMore text="news" onClick={() => setPage(p + 1)} />
      </div>
    </div>
  );
}
