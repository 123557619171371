import React from "react";

export default function GoBack(props: any) {
  return (
    <button type="button" aria-label="toggle menu" {...props}>
      <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
        <path
          fillRule="evenodd"
          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        ></path>
      </svg>
    </button>
  );
}
