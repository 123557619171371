import React, { useState, MouseEventHandler } from "react";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import IconMenu from "components/Common/IconMenu";
import Brand from "components/Common/Brand";

type Props = {
  onToggleMenu?: MouseEventHandler;
  className?: string;
};

export default function Navbar({ onToggleMenu, className = "" }: Props) {
  return (
    <nav className={`z-50 fixed w-full font-medium text-sm ${className}`}>
      <div className="px-2 py-1 sm:py-2 md:py-3 flex">
        <div className="flex flex-initial">
          <div className="flex ml-1 mr-3 md:hidden">
            <IconMenu
              className="text-foreground hover:text-foreground-300 focus:outline-none focus:text-foreground-300"
              onClick={onToggleMenu}
            />
          </div>
          <div className="hidden md:block mt-2 text-left text-sm uppercase font-semibold">
            <Link href="/" className="py-1">
              <Brand className="h-6 brand fill-current" />
            </Link>
          </div>
          <div className="md:hidden mt-2 text-left text-sm uppercase font-semibold">
            <Link
              className="text-sm uppercase hidden lg:inline-block font-semibold"
              href="/"
            >
              MyNews
            </Link>
          </div>
        </div>

        <div className="flex-1 flex-grow mx-auto">
          <div className="w-full">
            <form className="sm:px-12 md:w-8/12 mx-auto" action="">
              <div className="relative flex flex-wrap items-stretch w-full">
                <span className="absolute z-10 items-center justify-center w-8 h-full pt-2 pl-3 text-base font-normal leading-snug text-center text-gray-400 bg-transparent rounded">
                  <Icon icon="search" />
                </span>
                <input
                  type="text"
                  placeholder="Search news..."
                  name="q"
                  className="relative w-full px-3 py-1 md:py-2 pl-10 mt-1 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow outline-none focus:outline-none focus:shadow-outline"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="flex-initial">
          <div className="flex flex-row">
            {/*<button
              className="mx-4 text-gray-200 hover:text-gray-100 focus:text-gray-100 focus:outline-none"
              aria-label="show notifications"
            >
              <Icon icon="newspaper" />
            </button>*/}
          </div>
        </div>
      </div>
    </nav>
  );
}
